import React from "react"
import Layout from '../common/layouts';

export default () => {

  return (
    <Layout>
      <div className="pv5 pa2 flex flex-wrap mw9 center justify-around items-center">
        <div className="w-100 pa2 mw6 mv4">
          <span className="db f2 display dark-gray">
            Akusmatika, oidores de las matemáticas
          </span>
          <div className="lh-copy f5 serif mt4">
            Pitágoras estableció dos clases de discípulos: los matemáticos (mathematikoi, ‘conocedores’), con quienes compartía los detalles profundos de su doctrina, y los acusmáticos (akousmatikoi, ‘oidores’), que participaban de los ritos y prescripciones específicas de la hermandad, aún sin conocer en profundidad las razones de su credo y su proceder.
          </div>
        </div>
        <img className="w-100 mw6" src={"wordcloud.png"}/>
      </div>

    </Layout>
  )
}


